.horizontal-padding {
  padding: 0 15px;
}


body {
  font-family: "Poppins", sans-serif;
  padding: 0 !important;
  margin: 0 !important;
}

nav{
  color: #ffffff;
  background-color: #243657;
  padding: 10px;
  margin-bottom: 10px;
}

footer{
  background-color: #566074;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}

h1,  h2,  h3,  h4,  h5,  h6 {
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}
#header_image{
  max-width: 120px;
  vertical-align: center;
}
#header_title{
  font-size: 40px;
  display: inline;
  margin-left: 10px;
  vertical-align: center;
}
.errors {
  position: absolute;
  z-index: 10000;
  display: grid;
  gap: 10px;
  right: 30px;
  bottom: 30px;
}
.errors .error {
  background-color: #d51f1f;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  min-width: 180px;
  border-radius: 8px;
  cursor: pointer;
}
.linkbutton{
  display: inline-block;
  font-size: 16px;
  min-height: 16px;
  border: 0px;
  margin: 2px;
  padding: 6px 10px 8px;
  border-radius: 20px;
  background-color:#566074;
  color: #f3f3f3;
  text-decoration: none;
}
.linkbutton-selected{
  display: inline-block;
  font-size: 16px;
  min-height: 16px;
  border: 0px;
  margin: 2px;
  padding: 6px 10px 8px;
  border-radius: 20px;
  background-color: #A2C0E0;
  color: #000000;
  text-decoration: none;
}
.linkbutton:hover:not([disabled]) {
  background-color: #FF910A;
  color: #000000;
}


button {
  display: inline;
  margin: 20px 10px 10px 0px;
  border: 0px;
  padding: 1px;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 0);
  color:  #FF910A;
  text-decoration: none;
  cursor: pointer;
}
.button-tableheader {
  color:  #FFFFFF;
  margin: 0px;
}
button:disabled {
  background-color: #F5C7A9;
  color: #BF9173;
}
button:hover:not([disabled]) {
  background-color: #FF910A;
  color: #000000;
}


input[type=number]{
  max-width: 50px;
}
input[type=text]{
  max-width: 100px;
}

textarea{
  resize: none;
}
#clipboardTextArea{
  transition:0.1s fade;
  -moz-transition:0.1s fade;
  -webkit-transition:0.1s fade;
  width: 100%;
  height: 20%;
  max-height: 600px;
  max-width: 1500px;
}
.highlight{
  background:#FFD1B3;
}

.main-content{
  background-color: #ffffff;
  border: 1px solid #566074;
  margin-top: 10px;  
  width:fit-content;  
  max-width: 1500px;
}

table{
  background:#D1D0D0;
  margin-top: 20px;
}
td {
    padding: 3px;
    vertical-align: center;
}
.table-toolbar{
    border-radius: 28px;
    background:#FFD1B3;
    display: flex;
    align-items: center;
    /* gap: 20px; */
    padding: 5px 10px 5px 25px;
}
.table-header{
    background-color: #566074;
    color: #ffffff;
    font-weight: 500;
    font-variant: normal;
    text-transform: none;
}
.table-label{
    margin-left: 5px;
    font-weight: bold;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 2px
}
.field input, .field select {
  height: 20px;
  padding: 1px 2px;
  margin-right: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.466);
  outline: none !important;
  border: none;
}
.field label {
  font-weight: 500;
  font-size: 12px
}

.auth-widget {
  display: flex;
  align-items: center;
  gap: 2px;
}

.auth-widget button {
  padding: 1px 4px;
  border-radius: 4px;
  margin: 0;
}

.guest-view {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #243657;
}

.login-form {
  width: 280px;
  padding: 25px;
  background-color: white;
  margin: 20vh auto;
}